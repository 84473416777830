import { hasValidConsent } from "./consent";

const slotNumbers = {};

// We keep track of the articles on the page, when this increase we reset all the slot numbers.
let articleAmount = [...document.querySelectorAll("article")].length;

function getSlotNumber(slotName, slotNumber) {
  if (articleAmount !== [...document.querySelectorAll("article")].length) {
    articleAmount = [...document.querySelectorAll("article")].length;
    resetSlotNumberTargeting();
  }

  if (!slotNumbers[slotName]) {
    slotNumbers[slotName] = {
      slotNameNumber: 0,
      slotNameNumberTargeting: 0
    }
  }

  slotNumbers[slotName].slotNameNumber++;

  if (slotNumber) {
    if (slotNumber >= slotNumbers[slotName].slotNameNumberTargeting) {
      updateCorrelator();
    }
    slotNumbers[slotName].slotNameNumberTargeting = slotNumber;

    return slotNumbers[slotName];
  }

  slotNumbers[slotName].slotNameNumberTargeting++;

  return slotNumbers[slotName];
}

let refreshRecently = false;
function updateCorrelator() {
  if (hasValidConsent()) {
    window.googletag = window.googletag || { cmd: [] };

    // Per documentation, refreshing should only be done every 30 seconds to avoid throttling.
    // Instead of doing 30 however, we do 3 seconds to avoid multiple refreshes in a row.
    // https://developers.google.com/publisher-tag/guides/control-ad-loading#best_practices
    if (refreshRecently) return;

    window.googletag.cmd.push(() => {
      window.googletag.pubads().refresh(null, {
        changeCorrelator: true
      })
      refreshRecently = true;

      setTimeout(() => {
        refreshRecently = false;
      }, 3_000);
    })
  }
}

function resetSlotNumberTargeting() {
  for (const key in slotNumbers) {
    slotNumbers[key].slotNameNumberTargeting = 0;
    updateCorrelator();
  }
}

window.Bad = {
  ...window.Bad || {},
  resetSlotNumberTargeting
};

export { getSlotNumber, resetSlotNumberTargeting };